import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { AboutHalva } from "../components/aboutHalva"
import { FormPKW } from "../components/FormPKW"
import { NewFooter } from "../components/NewFooter"
import { BannerWithDescription, ShapkaImg } from "../components/Banners/BannerShapka"
import { getPageData } from "../helpers/getPageData"
import { TwoChoice } from "../components/twoChoice"
import { Questions } from "../components/Questions"
import { QUESTIONS_FOR_SHAPKA } from "../components/Questions/helpers"
import { Map } from "../components/Map"

import { PageData } from "../interfaces/pageProps"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const seoData = {
    title: "Получите стикер в зимнем отделении Халвы!",
    description:
      "Ищите огромную вязаную шапку на ВДНХ. Оформите платежный стикер и получите вкусный подарок!",
  }

  const TwoChoiceTitle = (
    <>
      Для оформления стикера <br /> потребуется карта «Халва»
    </>
  )

  const TwoChoiceBlockTitle = (
    <>
      Если есть «Халва», оформи <br /> стикер на месте
    </>
  )

  const TwoChoiceSecondBlockTitle = (
    <>
      Если нет «Халвы», оформи ее онлайн или в отделении. После получения карты забери свой
      новогодний стикер.
    </>
  )

  return (
    <Layout seoData={seoData} noHeader noFooter noIndex={noIndex}>
      <NewHeader withoutHintAndCountDown />
      <BannerWithDescription Img={ShapkaImg} orderNum="1" />
      <HowInstallmentWorks orderNum="2" variant="shapka" headTitle={<>Как получить стикер?</>} />
      <TwoChoice
        textBlockTitle={TwoChoiceBlockTitle}
        secondTextBlockTitle={TwoChoiceSecondBlockTitle}
        title={TwoChoiceTitle}
        orderNum="3"
        variant="shapka"
      />
      <AboutHalva orderNum="4" />
      <FormPKW
        title={
          <>
            Оформи карту «Халва», чтобы <br /> получить платежный стикер
          </>
        }
        dataLayerName="shortPersonalForm"
        formBtnText="Оформить карту"
        orderNum="5"
        emptyForm={false}
      />
      <Map />
      <Questions questionList={QUESTIONS_FOR_SHAPKA} orderNum="6" />
      <NewFooter ligal={ligal} orderNum="7" withSocialLinks withStoreLinks />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/shapka/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
